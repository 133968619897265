<template>
  <Validator v-slot="{ showErrors, valid }">
    <div class="UserForm">
      <Alert v-if="hasPendingChanges" :level="AlertLevel.WARNING" hideIcon>
        {{ $t('profile.message.progress_saving') }}
      </Alert>
      <div class="UserForm__addressType">
        <RadioGroup
          id="addressType"
          :options="address_types"
          v-model="values.address_type"
          row
          :disabled="disabled"
        />
      </div>
      <div class="UserForm__inputs">
        <Input
          v-if="values.address_type !== 1"
          :label="
            values.address_type === 3 ? $t('address.organization') : $t('address.company_name')
          "
          :disabled="disabled"
          :class="['UserForm__input', 'UserForm__input--wide']"
          v-model="values.company_name"
        />
        <Input
          :label="$t('person.first_name')"
          :disabled="disabled"
          class="UserForm__input"
          v-model="values.first_name"
        />
        <Input
          :label="$t('person.last_name')"
          :disabled="disabled"
          class="UserForm__input"
          v-model="values.last_name"
        />
        <div :class="['UserForm__input', 'UserForm__input--wide']">
          <Input type="tel" :disabled="disabled" v-model="values.mobile_phone_number">
            {{ $t('person.mobile_phone_number') }}
            <span v-if="!disabled && verifiedMobile" class="UserForm__verified">
              <i class="fas fa-check-circle" />
              {{ $t('profile.message.verified') }}
            </span>
          </Input>
          <Alert
            v-if="!disabled && !verifiedMobile"
            :level="AlertLevel.WARNING"
            hideIcon
            class="UserForm__alert"
          >
            <i18n path="profile.message.missing_verification">
              <template #mobile_number>
                <span class="UserForm__strong">{{ $t('person.mobile_phone_number--long') }}</span>
              </template>
            </i18n>
            <Button
              :size="ButtonSizes.SMALL"
              :color="ButtonColors.WHITE"
              @click="$refs.dialog.open()"
              class="UserForm__alertButton"
            >
              {{ $t('profile.controls.verify_mobile_phone_number') }}
            </Button>
          </Alert>
        </div>
        <Input
          :label="$t('address.street_name')"
          :disabled="disabled"
          class="UserForm__input"
          :patternErrorMessage="$t('input.invalid_street')"
          pattern="^(.(?!.*(\d|st|nr)(\s|\W\s?)?(st|tv|th)|.*\d(\.|\s|\.\s)?sal|\d\w|\d\s?\w$))*[^\d]$"
          v-model="values.address.street_name"
        />
        <Input
          :label="$t('address.house_number')"
          :maxlength="10"
          pattern="^(\d*)$"
          :patternErrorMessage="$t('input.invalid_numbers_only')"
          :disabled="disabled"
          class="UserForm__input"
          v-model="values.address.house_number"
          validateOnBlur
          @blur="sanitizeHouseNumber"
        />
        <Input
          :label="$t('address.floor')"
          :maxlength="10"
          :disabled="disabled"
          class="UserForm__input"
          v-model="values.address.floor"
        />
        <Input
          :label="$t('address.postal_code')"
          :maxlength="4"
          pattern="^(\d*)$"
          :patternErrorMessage="$t('input.invalid_numbers_only')"
          :disabled="disabled"
          class="UserForm__input"
          v-model="values.address.postal_code"
        />
        <Input
          :label="$t('address.city')"
          :disabled="disabled"
          class="UserForm__input"
          v-model="values.address.city"
        />
        <CountrySelect
          :label="$t('address.country')"
          :disabled="disabled"
          class="UserForm__input"
          v-model="values.address.country_id"
        />
      </div>
      <div class="UserForm__footer">
        <Button @click="!valid ? showErrors() : updateAccount()" :disabled="disabled">
          {{ $t('profile.controls.save') }}
        </Button>
        <ProgressStatus
          :progress="accountContainer.accountUpdatingStatus"
          :workingLabel="$t('profile.account_update_status.working')"
          :completeLabel="$t('profile.account_update_status.complete')"
          :errorLabel="$t('profile.account_update_status.error')"
          class="UserForm__status"
        />
      </div>
      <PhoneVerificationDialog
        v-if="!verifiedMobile"
        ref="dialog"
        :number="accountContainer.account.mobile_phone_number"
        :countryCode="accountContainer.account.address.country_id"
      />
    </div>
  </Validator>
</template>

<script>
import Validator from '@containers/Validator';
import Alert, { AlertLevel } from '@components/Alert';
import RadioGroup from '@components/RadioGroup';
import { Button, sizes as ButtonSizes, colors as ButtonColors } from '@components/Buttons';
import ProgressStatus from '@components/ProgressStatus';
import Input from '@scenes/SelfserviceScene/components/Input';
import CountrySelect from '@scenes/SelfserviceScene/components/CountrySelect';
import PhoneVerificationDialog from '@scenes/SelfserviceScene/components/PhoneVerificationDialog';
import Progress from '@types/Progress';

export default {
  name: 'UserForm',

  components: {
    Validator,
    Alert,
    RadioGroup,
    Button,
    ProgressStatus,
    Input,
    CountrySelect,
    PhoneVerificationDialog,
  },

  enums: {
    AlertLevel,
    ButtonSizes,
    ButtonColors,
  },

  data() {
    return {
      values: {
        first_name: '',
        last_name: '',
        phone_number: '',
        mobile_phone_number: '',
        address: {
          street_name: '',
          house_number: '',
          floor: '',
          postal_code: '',
          city: '',
          country_id: '',
        },
        company_name: '',
        address_type: 1,
      },

      address_types: [
        {
          value: 1,
          text: this.$t('address.types.private'),
        },
        {
          value: 2,
          text: this.$t('address.types.company'),
        },
        {
          value: 3,
          text: this.$t('address.types.organization'),
        },
      ],
    };
  },

  computed: {
    hasPendingChanges() {
      const keys = ['address', 'first_name', 'last_name', 'mobile_phone_number', 'address_type'];
      return this.accountContainer.pendingOn(keys);
    },
    disabled() {
      return (
        !this.accountContainer.account ||
        this.accountContainer.accountStatus === Progress.WORKING ||
        this.accountContainer.accountUpdatingStatus === Progress.WORKING ||
        this.hasPendingChanges
      );
    },
    verifiedMobile() {
      return this.accountContainer.account.verified.includes('mobile_phone_number');
    },
  },

  watch: {
    'accountContainer.account': {
      immediate: true,
      handler(to) {
        if (to) {
          this.updateValues(to);
        }
      },
    },
  },

  methods: {
    updateValues(account) {
      for (const [key, value] of Object.entries(this.values)) {
        if (value !== null && typeof value === 'object') {
          Object.keys(value).forEach(nestedKey => {
            this.values[key][nestedKey] = account[key][nestedKey];
          });
        } else {
          this.values[key] = account[key];
        }
      }
    },
    updateAccount() {
      this.accountContainer.updateAccount(this.values);
    },
    sanitizeHouseNumber() {
      if (!this.values.address.house_number || this.values.address.floor) return;

      const lastIndex = this.values.address.house_number.length - 1;
      if (+this.values.address.house_number[lastIndex]) return;

      this.values.address.floor = this.values.address.house_number[lastIndex];
      this.values.address.house_number = this.values.address.house_number.slice(0, lastIndex);
    },
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.UserForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  @include spacing-2('gap');
  align-items: flex-start;
  @include maxBreakpoint(760) {
    align-items: stretch;
  }

  .UserForm__inputs {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include spacing-2('gap');
  }

  .UserForm__input {
    flex: 1;
    grid-column: span 1;
    &--wide {
      grid-column: span 2;
    }
  }

  .UserForm__verified {
    color: $color-extra-success;
    @include spacing-1('margin-left');
  }

  .UserForm__alert {
    @include spacing-1('margin-top');
  }

  .UserForm__alertButton {
    display: block;
    margin-top: 10px;
  }

  .UserForm__strong {
    font-weight: bold;
    text-transform: lowercase;
  }

  .UserForm__footer {
    display: flex;
    align-items: center;
    @include spacing-3('gap');
  }

  .UserForm__status {
    font-size: 15px;
  }
}
</style>
