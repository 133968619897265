<template>
  <ModalDialog
    ref="modal"
    :title="$t('profile.controls.change_primary_email')"
    :working="working"
    :confirmLabel="$t('profile.controls.change_email')"
    @confirm="submit"
  >
    <template v-if="error">
      <Alert v-if="error === 'exists'" :level="AlertLevel.WARNING" hideIcon>
        {{ $t('profile.message.email_already_added') }}
      </Alert>
      <Alert v-else-if="error === 'occupied'" :level="AlertLevel.WARNING" hideIcon>
        {{ $t('profile.message.email_occupied') }}
      </Alert>
      <Alert v-else :level="AlertLevel.ERROR" hideIcon>
        {{ $t('error_messages.try_again') }}
      </Alert>
      <br />
    </template>
    {{ $t('profile.message.choose_email', { email: this.email }) }}
  </ModalDialog>
</template>

<script>
import { ModalDialog } from '@components/Modal';
import Alert, { AlertLevel } from '@components/Alert';

export default {
  name: 'ChangePrimaryEmailModal',

  components: {
    ModalDialog,
    Alert,
  },

  enums: {
    AlertLevel,
  },

  data() {
    return {
      working: false,
      email: '',
      error: '',
    };
  },

  methods: {
    submit() {
      this.working = true;
      this.accountContainer
        .setPrimaryEmail(this.email)
        .then(() => {
          this.close();
        })
        .catch(errors => {
          this.working = false;
          const errorCode = errors && errors.length ? errors[0]?.code : '';
          this.error = errorCode === 'mail_occupied' ? 'occupied' : 'error';
        });
    },
    open(email) {
      this.email = email;
      this.$refs.modal.open();
    },
    close() {
      this.error = '';
      this.$refs.modal.close();
    },
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>
