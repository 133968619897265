<template>
  <Validator v-slot="{ valid }">
    <Modal ref="modal" :title="$t('profile.controls.add_email')" :working="working">
      <template #default>
        <template v-if="error">
          <Alert v-if="error === 'exists'" :level="AlertLevel.WARNING" hideIcon closeable>
            {{ $t('profile.message.email_already_added') }}
          </Alert>
          <Alert v-else-if="error === 'occupied'" :level="AlertLevel.WARNING" hideIcon closeable>
            {{ $t('profile.message.email_occupied') }}
          </Alert>
          <Alert v-else :level="AlertLevel.ERROR" hideIcon closeable>
            {{ $t('error_messages.try_again') }}
          </Alert>
          <br />
        </template>
        <Input
          id="newEmail"
          v-model="email"
          name="newEmail"
          type="email"
          :label="$t('email')"
          autoFocus
          required
          validateOnBlur
        />
      </template>
      <template #controls="{ close }">
        <Button :color="ButtonColors.WHITE" @click="close">{{ $t('cancel') }}</Button>
        <Button :disabled="!valid" @click="submit">{{ $t('profile.controls.add_email') }}</Button>
      </template>
    </Modal>
  </Validator>
</template>

<script>
import Validator from '@containers/Validator';
import { Modal } from '@components/Modal';
import Alert, { AlertLevel } from '@components/Alert';
import { Button, colors as ButtonColors } from '@components/Buttons';
import Input from '@scenes/SelfserviceScene/components/Input';

export default {
  name: 'AddEmailModal',

  components: {
    Validator,
    Modal,
    Alert,
    Button,
    Input,
  },

  enums: {
    AlertLevel,
    ButtonColors,
  },

  data() {
    return {
      working: false,
      email: '',
      error: '',
    };
  },

  watch: {
    email() {
      if (this.error) this.error = '';
    },
  },

  methods: {
    submit() {
      if (this.accountContainer.hasEmail(this.email)) {
        this.error = 'exists';
        return;
      }
      this.working = true;
      this.accountContainer
        .addEmail(this.email)
        .then(() => {
          this.close();
        })
        .catch(errors => {
          this.working = false;
          const errorCode = errors && errors.length ? errors[0]?.code : '';
          this.error = errorCode === 'mail_occupied' ? 'occupied' : 'error';
        });
    },
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.error = '';
      this.$refs.modal.close();
    },
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>
