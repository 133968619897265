<template>
  <div :class="['FavoriteStoreLarge', !isMobileSize && 'FavoriteStoreLarge--large']">
    <div class="FavoriteStoreLarge__container" v-if="accountContainer.account.branch">
      <BranchCardLarge v-if="!isMobileSize" :branch="accountContainer.account.branch" />
      <BranchCard v-else :branch="accountContainer.account.branch" active />
      <Button
        :size="sizes.SMALL"
        :color="colors.WHITE"
        :icon="icons.PEN"
        @click="$refs.favoriteStoreSelect.open()"
      >
        {{ $t('profile.controls.edit_branch') }}
      </Button>
    </div>
    <template v-else>
      <BranchCardLargeEmpty v-if="!isMobileSize" @click="$refs.favoriteStoreSelect.open()">
        {{ $t('profile.controls.choose_branch') }}
      </BranchCardLargeEmpty>
      <BranchCardEmpty v-else @click="$refs.favoriteStoreSelect.open()">
        {{ $t('profile.controls.choose_branch') }}
      </BranchCardEmpty>
    </template>
    <FavoriteStoreSelect ref="favoriteStoreSelect" />
  </div>
</template>

<script>
import {
  BranchCardLarge,
  BranchCard,
  BranchCardLargeEmpty,
  BranchCardEmpty,
} from '@components/Cards';
import { Button, sizes, colors, icons } from '@components/Buttons';
import FavoriteStoreSelect from '@components/FavoriteStoreSelect';

export default {
  name: 'FavoriteStoreLarge',

  components: {
    BranchCardLarge,
    BranchCard,
    BranchCardLargeEmpty,
    BranchCardEmpty,
    Button,
    FavoriteStoreSelect,
  },

  enums: {
    sizes,
    colors,
    icons,
  },

  computed: {
    isMobileSize() {
      return ['mobileS', 'mobileM', 'mobileL'].includes(this.$mq);
    },
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.FavoriteStoreLarge {
  width: 100%;

  .FavoriteStoreLarge__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include spacing-2('gap');
  }

  &--large {
    width: 280px;
  }
}
</style>
