<template>
  <div class="ProfileScene">
    <h2 class="ft-h2">{{ $route.meta.title }}</h2>
    <SkeletonTransition :ready="accountContainer.account !== null">
      <template #default>
        <section class="ProfileScene__sections">
          <Section>
            <template #details>
              <UserCard :name="cardName" :email="accountContainer.account.email_address" />
            </template>
          </Section>

          <Section :title="$t('profile.information')">
            <FormsContainer>
              <UserForm />
            </FormsContainer>
          </Section>

          <Section :title="$t('email')">
            <EmailManager />
          </Section>

          <Section :title="$t('account.branch')">
            <FavoriteStoreLarge />
          </Section>

          <Section :title="$t('password')">
            <AuthenticationContainer>
              <ChangePasswordModal ref="changePasswordModal" />
            </AuthenticationContainer>

            <Button
              :color="colors.WHITE"
              :size="sizes.SMALL"
              @click="$refs.changePasswordModal.open()"
            >
              {{ $t('profile.controls.change_password') }}
            </Button>
          </Section>

          <Section
            v-if="$isEnabled(Features.ACCOUNT_PROFILE_NEWSLETTER)"
            :title="$t('profile.newsletter')"
          >
            <NewsletterManager />
          </Section>

          <Section
            v-if="$isEnabled(Features.ACCOUNT_PROFILE_NEWSLETTER)"
            :title="$t('profile.interests')"
          >
            <Interests />
          </Section>

          <Section :title="$t('profile.login_history')">
            <Button
              :color="colors.WHITE"
              :size="sizes.SMALL"
              icon="far fa-stream"
              @click="openLoginHistoryModal"
            >
              {{ $t('profile.controls.view_login_history') }}
            </Button>

            <Modal
              ref="loginHistoryDialog"
              :title="$t('profile.login_history')"
              :size="ModalSizes.LARGE"
            >
              <template #default="{ setWorking }">
                <LoginHistory @status="status => setWorking(status === Progress.WORKING)" />
              </template>
              <template #controls="{ close }">
                <Button type="light" @click.prevent="close">
                  {{ $t('profile.controls.close_modal') }}
                </Button>
              </template>
            </Modal>
          </Section>

          <Section :title="$t('profile.delete_account')">
            <Button
              :color="colors.RED"
              :size="sizes.SMALL"
              :icon="icons.TRASH"
              @click="$refs.deleteAccountDialog.open()"
            >
              {{ $t('profile.controls.delete_account') }}
            </Button>
            <ModalDialog
              ref="deleteAccountDialog"
              :title="$t('profile.controls.delete_account')"
              :working="accountDeleteStatus === Progress.WORKING"
              :confirmLabel="$t('profile.controls.confirm_delete_account')"
              :confirmColor="colors.RED"
              @confirm="deleteAccount"
            >
              {{ $t('profile.message.delete_account') }}
            </ModalDialog>
          </Section>
        </section>
      </template>
      <template #skeleton>
        <section class="ProfileScene__sections">
          <Section>
            <template #details>
              <UserCardSkeleton />
            </template>
          </Section>

          <Section>
            <template #header>
              <SkeletonGraphic :width="180" :height="22" />
            </template>
            <UserFormSkeleton />
          </Section>
        </section>
      </template>
    </SkeletonTransition>
  </div>
</template>

<script>
import FormsContainer from '@containers/FormsContainer';
import AuthenticationContainer from '@containers/AuthenticationContainer';
import {
  SkeletonTransition,
  SkeletonGraphic,
  UserCardSkeleton,
  UserFormSkeleton,
} from '@components/Skeleton';
import Section from './layouts/Section';
import UserCard from '@scenes/SelfserviceScene/components/UserCard';
import UserForm from './components/UserForm';
import Toggle from '@components/Toggle';
import EmailManager from './components/EmailManager';
import FavoriteStoreLarge from './components/FavoriteStoreLarge';
import ChangePasswordModal from './components/ChangePasswordModal';
import Interests from './components/Interests';
import LoginHistory from './components/LoginHistory';
import { Button, colors, sizes, icons } from '@components/Buttons';
import { Modal, ModalDialog, Sizes as ModalSizes } from '@components/Modal';
import Features from '@types/Features';
import Progress from '@types/Progress';
import NewsletterManager from './components/NewsletterManager';

export default {
  name: 'ProfileScene',

  components: {
    FormsContainer,
    AuthenticationContainer,
    SkeletonTransition,
    SkeletonGraphic,
    UserCardSkeleton,
    UserFormSkeleton,
    Section,
    UserCard,
    UserForm,
    Toggle,
    EmailManager,
    FavoriteStoreLarge,
    ChangePasswordModal,
    Interests,
    LoginHistory,
    Button,
    Modal,
    ModalDialog,
    NewsletterManager,
  },

  enums: {
    sizes,
    colors,
    icons,
    ModalSizes,
    Features,
    Progress,
  },

  data() {
    return {
      accountDeleteStatus: '',
    };
  },

  computed: {
    cardName() {
      if (this.accountContainer.fullName) {
        return this.accountContainer.fullName;
      } else if (this.accountContainer.account?.company_name) {
        return this.accountContainer.account.company_name;
      }
      return '';
    },
    hasPendingEmailChanges() {
      return (
        this.accountContainer.account?.pending_updates?.includes('secondary_email_addresses') ||
        this.accountContainer.account?.pending_updates?.includes('email_address')
      );
    },
    secondaryEmailAddresses() {
      if (!this.accountContainer.account.secondary_email_addresses) return [];
      return this.accountContainer.account.secondary_email_addresses.filter(emailObj => {
        return emailObj.email_address !== this.accountContainer.account.email_address;
      });
    },
  },

  created() {
    this.accountContainer.fetchAccount();
  },

  methods: {
    deleteAccount() {
      this.accountDeleteStatus = Progress.WORKING;
      this.accountContainer
        .deleteAccount()
        .then(() => {
          window.location.href = '/';
        })
        .catch(() => {
          this.accountDeleteStatus = Progress.ERROR;
          this.$refs.deleteAccountDialog.close();
        });
    },
    openLoginHistoryModal() {
      this.$refs.loginHistoryDialog.open();
    },
    closeLoginHistoryModal() {
      this.$refs.loginHistoryDialog.close();
    },
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.ProfileScene {
  .ProfileScene__sections {
    @include spacing-2('padding');

    > * {
      border-top: 1px solid #d0d0d0;
      @include spacing-4('padding-top' 'padding-bottom');

      &:first-child {
        border-top: none;
      }
    }
  }
}
</style>
