<template>
  <ModalDialog
    ref="modal"
    :working="working"
    :confirmColor="ButtonColors.RED"
    :confirmIcon="ButtonIcons.TRASH"
    title="Afmeld nyhedsbrev"
    confirmLabel="Afmeld"
    @confirm="submit"
  >
    <template v-if="error">
      <Alert :level="AlertLevel.ERROR" hideIcon closeable>
        {{ $t('error_messages.try_again') }}
      </Alert>
      <br />
    </template>
    Er du sikker på at du vil afmelde nyhedsbrev.
  </ModalDialog>
</template>

<script>
import { ModalDialog } from '@components/Modal';
import Alert, { AlertLevel } from '@components/Alert';
import { colors as ButtonColors, icons as ButtonIcons } from '@components/Buttons';

export default {
  name: 'UnsubscribeNewsletterModal',

  components: {
    ModalDialog,
    Alert,
  },

  enums: {
    AlertLevel,
    ButtonColors,
    ButtonIcons,
  },

  data() {
    return {
      working: false,
      error: '',
    };
  },

  methods: {
    submit() {
      // this.working = true;
      // this.accountContainer
      //   .deleteEmail(this.email)
      //   .then(() => {
      //     this.close();
      //   })
      //   .catch(e => {
      //     this.working = false;
      //     this.error = 'error';
      //   });
      this.$emit('submit');
      this.close();
    },
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.error = '';
      this.$refs.modal.close();
    },
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>
