<template>
  <Validator v-slot="{ valid, showErrors }">
    <Modal ref="modal" :title="$t('profile.controls.edit_email--long')" :working="working">
      <template #default>
        <template v-if="error">
          <Alert v-if="error === 'exists'" :level="AlertLevel.WARNING" hideIcon closeable>
            {{ $t('profile.message.email_already_added') }}
          </Alert>
          <Alert v-else-if="error === 'occupied'" :level="AlertLevel.WARNING" hideIcon closeable>
            {{ $t('profile.message.email_occupied') }}
          </Alert>
          <Alert v-else :level="AlertLevel.ERROR" hideIcon closeable>
            {{ $t('error_messages.try_again') }}
          </Alert>
          <br />
        </template>
        <InputList>
          <Input
            id="newEmail"
            v-model="editToEmail"
            name="newEmail"
            type="email"
            :label="$t('profile.controls.new_email')"
            auto-focus
            required
            validate-on-blur
          />
          <Input
            id="repeat_email"
            v-model="repeatEmail"
            :pattern="editToEmail"
            :patternErrorMessage="$t('input.invalid_email_no_match')"
            name="repeat_email"
            type="email"
            :label="$t('input.email.repeat')"
            required
          />
        </InputList>
      </template>
      <template #controls="{ close }">
        <Button :color="ButtonColors.WHITE" @click="close">{{ $t('cancel') }}</Button>
        <Button @click="valid ? submit() : showErrors()">{{ $t('profile.controls.save') }}</Button>
      </template>
    </Modal>
  </Validator>
</template>

<script>
import Validator from '@containers/Validator';
import InputList from '@layouts/InputList';
import { Modal } from '@components/Modal';
import Alert, { AlertLevel } from '@components/Alert';
import { Button, colors as ButtonColors } from '@components/Buttons';
import Input from '@scenes/SelfserviceScene/components/Input';

export default {
  name: 'EditEmailModal',

  components: {
    Validator,
    InputList,
    Modal,
    Alert,
    Button,
    Input,
  },

  enums: {
    AlertLevel,
    ButtonColors,
  },

  data() {
    return {
      working: false,
      email: '',
      editToEmail: '',
      repeatEmail: '',
      error: '',
    };
  },

  watch: {
    email() {
      if (this.error) this.error = '';
    },
  },

  methods: {
    submit() {
      if (this.accountContainer.hasEmail(this.editToEmail)) {
        this.error = 'exists';
        return;
      }
      this.working = true;
      this.accountContainer
        .editEmail({
          from: this.email,
          to: this.editToEmail,
        })
        .then(e => {
          this.close();
        })
        .catch(errors => {
          this.working = false;
          const errorCode = errors && errors.length ? errors[0]?.code : '';
          this.error = errorCode === 'mail_occupied' ? 'occupied' : 'error';
        });
    },
    open(email) {
      this.email = this.editToEmail = email;
      this.error = '';
      this.$refs.modal.open();
    },
    close() {
      this.error = '';
      this.$refs.modal.close();
    },
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>
