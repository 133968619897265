<template>
  <div class="ListItemControls">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ListItemControls',
};
</script>

<style lang="scss" scoped>
.ListItemControls {
  display: flex;
  @include spacing-1('gap');
}
</style>
