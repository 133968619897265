<template>
  <div class="Section">
    <div class="Section__container">
      <div class="Section__details">
        <div v-if="hasHeaderSlot || title" class="Section__header">
          <slot name="header">
            <span class="ft-h3">{{ title }}</span>
          </slot>
        </div>
        <slot name="details">
          <p class="Section__text ft-p2">{{ details }}</p>
        </slot>
      </div>
      <div class="Section__content">
        <slot name="default" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Section',

  props: {
    title: {
      type: String,
      default: '',
    },
    details: {
      type: String,
      default: '',
    },
  },

  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
  },
};
</script>

<style lang="scss" scoped>
.Section {
  margin: 0;

  .Section__header {
    display: flex;
    margin-top: 0;
    @include spacing-2('margin-bottom');
  }

  .Section__container {
    display: flex;
    @include spacing-2('gap');

    @include maxBreakpoint($tablet-numeric) {
      flex-direction: column;
    }
  }

  .Section__details {
    display: flex;
    flex: 1;
  }

  .Section__text {
    color: $color-prim-grey-dark;
    max-width: 230px;
    @include maxBreakpoint($tablet-numeric) {
      max-width: initial;
    }
  }

  .Section__content {
    display: flex;
    flex: 2;
    align-items: flex-start;
  }
}
</style>
