var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'Details',
    ("Details--" + _vm.columnSize),
    _vm.flex && 'Details--flex',
    _vm.smallHeader && 'Details--smallHeader' ]},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,class:[
      'Details__item',
      item.wide && 'Details__item--wide',
      item.ellipsis && 'Details__item--ellipsis',
      item.light && 'Details__item--light' ]},[_c('span',{staticClass:"Details__header"},[_vm._v(_vm._s(item.header))]),(item.template)?_c('span',{staticClass:"Details__label"},[_c(item.template,_vm._b({tag:"component"},'component',item.templateProps,false))],1):(item.label !== undefined && item.label !== '')?_c('span',{staticClass:"Details__label"},[(item.format === _vm.Formats.DATE)?[_c('Time',{attrs:{"datetime":item.label,"format":item.dateFormat || 'LLL'}})]:(item.format === _vm.Formats.PRICE)?[_c('Price',{attrs:{"value":item.label}})]:(item.format === _vm.Formats.LINK && item.linkTo)?[_c('router-link',{staticClass:"Details__link",attrs:{"to":item.linkTo}},[_vm._v(_vm._s(item.label))])]:(item.format === _vm.Formats.LINK && item.href)?[_c('a',{staticClass:"Details__link",attrs:{"href":item.href,"title":item.label}},[_vm._v(_vm._s(item.label))])]:[_vm._v(_vm._s(item.label))]],2):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }