<template>
  <div :class="['ProgressStatus', progressClass, hidden && 'ProgressStatus--hidden']">
    <template v-if="progress === Progress.WORKING || showWorking">
      <i class="ProgressStatus__icon far fa-spinner-third" />
      <div v-if="workingLabel">{{ workingLabel }}</div>
    </template>
    <template v-else-if="progress === Progress.COMPLETE">
      <i class="ProgressStatus__icon far fa-check" />
      <div v-if="completeLabel">{{ completeLabel }}</div>
    </template>
    <template v-else-if="progress === Progress.ERROR">
      <i class="ProgressStatus__icon far fa-times" />
      <div v-if="errorLabel">{{ errorLabel }}</div>
    </template>
    <div v-else></div>
  </div>
</template>

<script>
import Progress from '@types/Progress';

export default {
  name: 'ProgressStatus',

  enums: {
    Progress,
  },

  props: {
    progress: {
      type: String,
      required: true,
    },
    workingLabel: {
      type: String,
      default: '',
    },
    completeLabel: {
      type: String,
      default: '',
    },
    errorLabel: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      hidden: false,
      showWorking: false,
    };
  },

  computed: {
    progressClass() {
      if (this.showWorking) return 'ProgressStatus--working';
      switch (this.progress) {
        case Progress.WORKING:
          return 'ProgressStatus--working';
        case Progress.COMPLETE:
          return 'ProgressStatus--complete';
        case Progress.ERROR:
          return 'ProgressStatus--error';
        default:
          return '';
      }
    },
  },

  watch: {
    progress(to, from) {
      if (to !== from) {
        if (to === Progress.WORKING) {
          this.showWorking = true;
          setTimeout(() => (this.showWorking = false), 1000);
        }
        this.hidden = false;
        clearTimeout(this.timeoutHide);
      }
      if (to === Progress.COMPLETE && from !== Progress.COMPLETE) {
        this.timeoutHide = setTimeout(() => (this.hidden = true), 4000);
      }
    },
  },

  created() {
    this.timeoutHide = null;
  },

  beforeDestroy() {
    clearTimeout(this.timeoutHide);
  },
};
</script>

<style lang="scss" scoped>
@keyframes rotation {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

.ProgressStatus {
  display: flex;
  gap: 10px;
  align-items: center;

  .ProgressStatus__icon {
    font-size: 1.33em;
  }

  &--working .ProgressStatus__icon {
    color: $color-extra-info;
    animation: rotation 0.8s linear infinite;
  }

  &--complete .ProgressStatus__icon {
    color: $color-extra-success;
  }

  &--error .ProgressStatus__icon {
    color: $color-extra-error;
  }

  &--hidden {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
}
</style>
