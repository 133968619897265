<template>
  <ul class="List">
    <slot />
  </ul>
</template>

<script>
import ListItem from './components/ListItem';
import ListItemContent from './components/ListItemContent';
import ListItemControls from './components/ListItemControls';

export { ListItem, ListItemContent, ListItemControls };

export default {
  name: 'List',
};
</script>

<style lang="scss" scoped>
.List {
  display: flex;
  flex-direction: column;
  @include spacing-2('gap');
}
</style>
