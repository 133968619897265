<script>
import helpers from '@helpers';
import settings from '@settings';

const stripAmount = amount => {
  // Method for stripping parenthesis from the amount
  return amount !== null ? amount.toString().replace(/[()]/g, '') : '';
};

export default {
  name: 'Toggle',
  functional: true,
  props: {
    label: {
      required: true,
      type: String,
    },
    selected: {
      required: true,
      type: Boolean,
    },
    amount: {
      required: false,
      type: [String, Number],
      default: '',
    },
    action: {
      default: value => {
        if (settings.testmode) {
          console.log(
            'Value recieved. Remember to apply a method on the "action" prop for handling value change. New value: ',
            value,
            '!',
          );
        }
      },
      type: Function,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },

  render(createElement, context) {
    // Decontruct props
    let { amount } = context.props;
    const { label, selected, action, disabled } = context.props;

    // Set amount, minus parenthesis
    amount = stripAmount(amount);

    return (
      <div
        aria-disabled={disabled ? 'true' : 'false'}
        class={['Wrapper', disabled ? 'Wrapper--disabled' : ''].join(' ')}
        tabIndex={0}
        vOn:keydown={key => {
          if (key.keyCode === 13 || key.code === 'Enter') {
            // Only run the action if the component isn't set to be disabled
            if (disabled !== true) {
              action(!selected);
            }
          }
        }}
        onClick={() => {
          // Only run the action if the component isn't set to be disabled
          if (disabled !== true) {
            action(!selected);
          }
        }}
      >
        <div class="PureMaterialSwitch">
          {/* Input field for screen readers */}
          <input type="checkbox" class="NativeCheck" checked={selected} aria-hidden="false" />
          <span></span>
        </div>

        {/* Text label */}
        <div class={['Label'].join(' ')}>
          <div class="Label__inner">{label}</div>
        </div>
      </div>
    );
  },
};
</script>

<style scoped lang="scss">
.Wrapper {
  display: flex;
  align-items: center;
  font-size: 15px;
  cursor: pointer;

  &--disabled {
    opacity: 0.4;
    cursor: default;
  }
}

.NativeCheck {
  display: none;
}

.Label {
  flex-grow: 1;
  color: $color-black;
  overflow: hidden;

  &--companion {
    color: inherit;
    font-size: 0.78em;
    display: flex;
    align-items: center;
  }

  &__inner {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}

.Amount {
  flex-grow: 0;
  margin-left: 10px;
  vertical-align: middle;
  line-height: 1em;
}

/* Pure css material design switch */
.PureMaterialSwitch {
  z-index: 0;
  position: relative;
  display: flex;
  color: rgba($color-prim-grey-dark, 0.87);
  font-size: 16px;
  line-height: 1.5;
  margin-right: 15px;

  > input {
    appearance: none;
    z-index: -1;
    position: absolute;
    right: 6px;
    top: -8px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: rgba($color-prim-grey-dark, 0.38);
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s 0.1s, transform 0.2s 0.1s;

    &:checked {
      right: -10px;
      background-color: rgba($color-prim-brand, 1);
      + {
        span {
          &::before {
            background-color: rgba($color-prim-brand, 0.6);
          }
          &::after {
            background-color: rgba($color-prim-brand, 1);
            transform: translateX(16px);
          }
        }
      }

      &:active {
        + {
          span {
            &::before {
              background-color: rgba($color-prim-grey-dark, 0.38);
            }
          }
        }
      }

      &:disabled {
        + {
          span {
            &::before {
              background-color: rgba($color-prim-brand, 0.6);
            }
          }
        }
      }
    }

    &:focus {
      opacity: 0.12;
    }

    &:active {
      opacity: 1;
      transform: scale(0);
      transition: transform 0s, opacity 0s;
      + {
        span {
          &::before {
            background-color: rgba($color-prim-brand, 0.6);
          }
        }
      }
    }

    &:disabled {
      opacity: 0;
      + {
        span {
          color: rgba($color-prim-grey-dark, 1);
          opacity: 0.38;
          cursor: default;
          &::before {
            background-color: rgba($color-prim-grey-dark, 0.38);
          }
        }
      }
    }
  }

  > span {
    display: inline-block;
    width: 100%;
    cursor: pointer;

    &::before {
      content: '';
      display: inline-block;
      margin: 5px 0 5px 1px;
      border-radius: 7px;
      width: 36px;
      height: 14px;
      background-color: rgba($color-prim-grey-dark, 0.38);
      vertical-align: top;
      transition: background-color 0.2s, opacity 0.2s;
    }

    &::after {
      content: '';
      position: absolute;
      top: 2px;
      right: 16px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
      transition: background-color 0.2s, transform 0.2s;
    }
  }

  &:hover {
    > input {
      opacity: 0.04;

      &:focus {
        opacity: 0.16;
      }
    }
  }
}
</style>
