<template>
  <div class="EmailManager">
    <Alert v-if="hasPendingChanges" :level="AlertLevel.WARNING" hideIcon>
      {{ $t('profile.message.progress_saving') }}
    </Alert>
    <div class="EmailManager__column">
      <span>{{ $t('profile.primary_email') }}:</span>
      <List>
        <ListItem>
          <ListItemContent :title="accountContainer.account.email_address">
            {{ accountContainer.account.email_address }}
          </ListItemContent>
          <ListItemControls>
            <Link
              :disabled="hasPendingChanges"
              @click="$refs.editEmailModal.open(accountContainer.account.email_address)"
            >
              {{ $t('profile.controls.edit') }}
            </Link>
          </ListItemControls>
        </ListItem>
      </List>
    </div>
    <div class="EmailManager__column">
      <span>{{ $t('profile.extra_emails') }}:</span>
      <List>
        <ListItem v-for="(item, index) in secondaryEmailAddresses" :key="index">
          <ListItemContent :title="item.email_address">
            {{ item.email_address }}
          </ListItemContent>
          <ListItemControls>
            <Link
              :disabled="hasPendingChanges"
              @click="$refs.changePrimaryEmailModal.open(item.email_address)"
            >
              {{ $t('profile.controls.choose_as_primary') }}
            </Link>
            <Link
              :color="LinkColor.RED"
              :disabled="hasPendingChanges"
              @click="$refs.removeEmailModal.open(item.email_address)"
            >
              {{ $t('profile.controls.delete_email') }}
            </Link>
          </ListItemControls>
        </ListItem>
      </List>
    </div>

    <Button
      :size="sizes.SMALL"
      :color="colors.WHITE"
      :disabled="hasPendingChanges"
      @click="$refs.addEmailModal.open()"
    >
      + {{ $t('profile.controls.add_new_email') }}
    </Button>

    <AddEmailModal ref="addEmailModal" />
    <RemoveEmailModal ref="removeEmailModal" />
    <ChangePrimaryEmailModal ref="changePrimaryEmailModal" />
    <EditEmailModal ref="editEmailModal" />
  </div>
</template>

<script>
import Alert, { AlertLevel } from '@components/Alert';
import { Button, colors, sizes } from '@components/Buttons';
import Link, { LinkColor } from '@components/Link';
import List, { ListItem, ListItemContent, ListItemControls } from '../List';
import AddEmailModal from './components/AddEmailModal';
import RemoveEmailModal from './components/RemoveEmailModal';
import ChangePrimaryEmailModal from './components/ChangePrimaryEmailModal';
import EditEmailModal from './components/EditEmailModal';

export default {
  name: 'EmailManager',

  components: {
    Alert,
    Button,
    Link,
    List,
    ListItem,
    ListItemContent,
    ListItemControls,
    AddEmailModal,
    RemoveEmailModal,
    ChangePrimaryEmailModal,
    EditEmailModal,
  },

  enums: {
    AlertLevel,
    sizes,
    colors,
    LinkColor,
  },

  computed: {
    hasPendingChanges() {
      return (
        this.accountContainer.account?.pending_updates?.includes('secondary_email_addresses') ||
        this.accountContainer.account?.pending_updates?.includes('email_address')
      );
    },
    secondaryEmailAddresses() {
      if (!this.accountContainer.account.secondary_email_addresses) return [];
      return this.accountContainer.account.secondary_email_addresses.filter(emailObj => {
        return emailObj.email_address !== this.accountContainer.account.email_address;
      });
    },
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.EmailManager {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include spacing-2('gap');
  width: 100%;

  .EmailManager__column {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
</style>
