<template>
  <div
    tabindex="0"
    :class="[
      'Tag',
      checked && 'Tag--checked',
      focus && !checked && 'Tag--focus',
      focus && checked && 'Tag--checkedFocus',
      disabled && 'Tag--disabled',
    ]"
    @click="() => !disabled && toogleChecked()"
    @focus="focus = !disabled"
    @blur="focus = false"
    @keydown="onKeydown"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <i v-if="hover && checked" class="fas fa-times Tag__icon"></i>
    <i v-else-if="checked" class="far fa-check Tag__icon"></i>
    <!-- <i v-else class="fal fa-plus"></i> -->
    <span>{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: 'Tag',

  props: {
    label: {
      type: [String, Number],
      required: true,
    },
    value: {
      type: [Array, String],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      focus: false,
      hover: false,
    };
  },

  computed: {
    checked: {
      get() {
        if (Array.isArray(this.value)) {
          return this.value.indexOf(this.label) !== -1;
        } else {
          return this.value === this.label;
        }
      },
      set(isChecked) {
        if (Array.isArray(this.value)) {
          let currentValue = [...this.value];
          if (isChecked) {
            currentValue.push(this.label);
          } else {
            currentValue = currentValue.filter(item => item !== this.label);
          }
          this.$emit('input', currentValue);
        } else {
          if (isChecked) {
            this.$emit('input', this.label);
          } else if (this.value === this.label) {
            this.$emit('input', '');
          }
        }
      },
    },
  },

  methods: {
    onKeydown(key) {
      if (key.keyCode === 13 || key.code === 'Enter' || key.keyCode === 32) {
        this.toogleChecked();
      }
    },
    toogleChecked() {
      this.checked = !this.checked;
    },
  },
};
</script>

<style lang="scss" scoped>
.Tag {
  display: flex;
  align-items: center;
  background: #ffffff;
  color: #3071a9;
  border: 2px solid #3071a9;
  border-radius: 5px;
  padding: 5.5px 11px;
  height: 25px;
  user-select: none;
  cursor: pointer;
  gap: 5px;

  &--checked {
    background: #3071a9;
    color: #ffffff;
  }

  &--focus {
    color: lighten(#3071a9, 20%);
    border-color: lighten(#3071a9, 20%);
  }

  &--checkedFocus {
    background: lighten(#3071a9, 20%);
    color: #ffffff;
    border-color: lighten(#3071a9, 20%);
  }

  &:not(.Tag--disabled) {
    &:hover {
      background: lighten(#3071a9, 10%);
      border-color: lighten(#3071a9, 10%);
      color: #ffffff;
      transition: 0.15s;
    }
  }

  &--disabled {
    opacity: 0.3;
    cursor: default;
  }
  .Tag__icon {
    width: 14px;
  }
}
</style>
