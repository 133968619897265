<template>
  <div class="Interests">
    <TagCollection
      :items="Object.values(interests)"
      v-model="currentTags"
      :disabled="!accountContainer.profile"
    />
    <ProgressStatus
      :progress="accountContainer.profileUpdatingStatus"
      completeLabel="Gemt"
      class="Interests__status"
    />
  </div>
</template>

<script>
import AccountContainer from '@containers/AccountContainer';
import TagCollection from './components/TagCollection';
import ProgressStatus from '@components/ProgressStatus';

export default {
  name: 'Interests',

  components: {
    AccountContainer,
    TagCollection,
    ProgressStatus,
  },

  computed: {
    currentTags: {
      get() {
        if (!this.accountContainer.profile?.interests) return [];
        return this.accountContainer.profile.interests.map(interest => this.interests[interest]);
      },
      set(tags) {
        const keys = Object.keys(this.interests);
        const values = Object.values(this.interests);
        const interests = tags.map(tag => keys[values.indexOf(tag)]);
        this.accountContainer.updateProfile({ interests });
      },
    },
  },

  created() {
    this.accountContainer.fetchProfile();
    this.interests = {
      bil: 'Bil',
      'boern-i-bilen': 'Børn i bilen',
      'scooter-knallert-mc': 'Scooter / knallert / MC',
      cykler: 'Cykel',
      'fritid-og-leg': 'Fritid og leg',
      vaerktoej: 'Værktøj',
      elektronik: 'Elektronik',
      camping: 'Camping',
      outdoor: 'Outdoor',
      baad: 'Båd',
      skisport: 'Skisport',
      'tilbud ': 'Tilbud',
      fyrvaerkeri: 'Fyrværkeri',
    };
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.Interests {
  position: relative;

  .Interests__status {
    position: absolute;
    transform: translateY(100%);
    bottom: -20px;
    font-size: 12px;
  }
}
</style>
