<template>
  <Collapse>
    <div v-if="pageRecords.length" class="LoginHistory">
      <LoginHistoryCard v-for="(record, index) in pageRecords" :key="index" :record="record" />
      <Pagination
        v-if="totalPages > 1"
        :page="page"
        :total="totalPages"
        class="LoginHistory__pagination"
        @change="newPage => (page = newPage)"
      />
    </div>
  </Collapse>
</template>

<script>
import { getLoginHistory } from '@services/thansen/authentication';
import Collapse from '@transitions/Collapse';
import Pagination from '@components/Pagination2';
import LoginHistoryCard from './components/LoginHistoryCard';
import Progress from '@types/Progress';

export default {
  name: 'LoginHistory',

  components: {
    Collapse,
    Pagination,
    LoginHistoryCard,
  },

  data() {
    return {
      loginHistory: [],
      page: 1,
      itemsPerPage: 6,
    };
  },

  computed: {
    pageRecords() {
      return this.loginHistory.slice(
        (this.page - 1) * this.itemsPerPage,
        this.page * this.itemsPerPage,
      );
    },
    totalPages() {
      return Math.ceil(this.loginHistory.length / this.itemsPerPage);
    },
  },

  created() {
    this.$emit('status', Progress.WORKING);
    getLoginHistory()
      .then(data => {
        this.loginHistory = data;
        this.$emit('status', Progress.COMPLETE);
      })
      .catch(error => {
        this.$emit('status', Progress.ERROR);
      });
  },
};
</script>

<style lang="scss" scoped>
.LoginHistory {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .LoginHistory__pagination {
    margin-top: 20px;
  }
}
</style>
