<template>
  <ModalDialog
    ref="modal"
    :title="$t('profile.controls.delete_email')"
    :working="working"
    :confirmLabel="$t('profile.controls.delete_email')"
    :confirmColor="ButtonColors.RED"
    :confirmIcon="ButtonIcons.TRASH"
    @confirm="submit"
  >
    <template v-if="error">
      <Alert :level="AlertLevel.ERROR" hideIcon closeable>
        {{ $t('error_messages.try_again') }}
      </Alert>
      <br />
    </template>
    {{ $t('profile.message.delete_email', { email: this.email }) }}
  </ModalDialog>
</template>

<script>
import { ModalDialog } from '@components/Modal';
import Alert, { AlertLevel } from '@components/Alert';
import { colors as ButtonColors, icons as ButtonIcons } from '@components/Buttons';

export default {
  name: 'RemoveEmailModal',

  components: {
    ModalDialog,
    Alert,
  },

  enums: {
    AlertLevel,
    ButtonColors,
    ButtonIcons,
  },

  data() {
    return {
      working: false,
      email: '',
      error: '',
    };
  },

  methods: {
    submit() {
      this.working = true;
      this.accountContainer
        .deleteEmail(this.email)
        .then(() => {
          this.close();
        })
        .catch(e => {
          this.working = false;
          this.error = 'error';
        });
    },
    open(email) {
      this.email = email;
      this.$refs.modal.open();
    },
    close() {
      this.error = '';
      this.$refs.modal.close();
    },
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>
