<template>
  <router-link
    v-if="to"
    :is="disabled ? 'span' : 'router-link'"
    :to="to"
    :title="title ? title : routeTitle"
    v-bind="$attrs"
    :class="['Link', disabled && 'Link--disabled', `Link--${color}`]"
  >
    <slot name="default" />
  </router-link>
  <button
    v-else
    @click="e => $emit('click', e)"
    :disabled="disabled"
    v-bind="$attrs"
    :class="['Link', disabled && 'Link--disabled', `Link--${color}`]"
  >
    <slot name="default" />
  </button>
</template>

<script>
export const LinkColor = {
  LIGHT: 'light',
  RED: 'red',
};

export default {
  name: 'Link',

  props: {
    to: {
      type: Object,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: LinkColor.LIGHT,
    },
  },

  computed: {
    routeTitle() {
      const path = `routes[${this.to?.name}].title`;
      if (this.to?.name && this.$te(path)) return this.$t(path);
      return undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.Link {
  display: inline-block;
  margin: -5px 0;
  border: none;
  padding: 5px 0;
  white-space: nowrap;
  background-color: initial;
  text-decoration: underline;
  cursor: pointer;

  &:not(.Link--disabled):hover {
    opacity: 0.5;
  }

  &--light {
    color: $color-prim-grey-dark;
  }

  &--red {
    color: $color-extra-error;
  }

  &--disabled {
    user-select: none;
    color: #dddddd;
    cursor: initial;
  }
}
</style>
