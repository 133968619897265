<template>
  <div
    :class="[
      'Details',
      `Details--${columnSize}`,
      flex && 'Details--flex',
      smallHeader && 'Details--smallHeader',
    ]"
  >
    <div
      v-for="(item, index) in items"
      :key="index"
      :class="[
        'Details__item',
        item.wide && 'Details__item--wide',
        item.ellipsis && 'Details__item--ellipsis',
        item.light && 'Details__item--light',
      ]"
    >
      <span class="Details__header">{{ item.header }}</span>
      <span v-if="item.template" class="Details__label">
        <component :is="item.template" v-bind="item.templateProps" />
      </span>

      <span v-else-if="item.label !== undefined && item.label !== ''" class="Details__label">
        <template v-if="item.format === Formats.DATE">
          <Time :datetime="item.label" :format="item.dateFormat || 'LLL'" />
        </template>
        <template v-else-if="item.format === Formats.PRICE">
          <Price :value="item.label" />
        </template>
        <template v-else-if="item.format === Formats.LINK && item.linkTo">
          <router-link class="Details__link" :to="item.linkTo">{{ item.label }}</router-link>
        </template>
        <template v-else-if="item.format === Formats.LINK && item.href">
          <a class="Details__link" :href="item.href" :title="item.label">{{ item.label }}</a>
        </template>
        <template v-else>{{ item.label }}</template>
      </span>
    </div>
  </div>
</template>

<script>
import Price from '@components/Price';
import Time from '@components/Time';

export const Sizes = {
  TINY: 'tiny',
  SMALL: 'small',
  MEDIUM: 'medium',
};

export const Formats = {
  DATE: 'DATE',
  PRICE: 'PRICE',
  LINK: 'LINK',
};

export default {
  name: 'Details',

  components: {
    Time,
    Price,
  },

  enums: {
    Formats,
  },

  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    columnSize: {
      type: String,
      default: Sizes.MEDIUM,
    },
    flex: {
      type: Boolean,
      default: false,
    },
    smallHeader: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    items() {
      return this.data.filter(item => typeof item === 'object' && item);
    },
  },
};
</script>

<style lang="scss" scoped>
.Details {
  width: 100%;
  justify-content: space-between;

  &:not(.Details--flex) {
    justify-content: space-between;
    display: grid;
    grid-gap: 1em 1.2em;
    &.Details--tiny {
      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    }
    &.Details--small {
      grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    }
    &.Details--medium {
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    }
    @media print {
      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr)) !important;
    }
  }

  &--flex {
    display: flex;
    justify-content: flex-start;
    gap: 1em;
  }

  .Details__item--wide {
    grid-column: span 2;
  }

  .Details__header {
    display: block;
    color: #878787;
  }

  &--smallHeader .Details__header {
    font-size: 13px;
  }

  .Details__label {
    display: block;
  }

  :not(.Details__item--light) .Details__label {
    font-weight: bold;
  }

  .Details__item--ellipsis .Details__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Details__link {
    color: $color-prim-blue;
    text-decoration: underline;
  }
}
</style>
