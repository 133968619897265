<template>
  <DetailsCard :details="details" class="LoginHistoryCard" />
</template>

<script>
import DetailsCard, { Formats } from '@scenes/SelfserviceScene/components/DetailsCard';

export default {
  name: 'LoginHistoryCard',

  components: {
    DetailsCard,
  },

  props: {
    record: {
      type: Object,
      required: true,
    },
  },

  computed: {
    showIP() {
      return this.$config.DOMAIN_CODE !== 'VAU';
    },
    showUser() {
      return this.$config.DOMAIN_CODE === 'VAU';
    },
    details() {
      return [
        this.showIP && { header: this.$t('login_history.table.header.ip'), label: this.record.ip },
        this.showUser && {
          header: this.$t('login_history.table.header.user'),
          label: this.record.user,
        },
        this.showUser && {
          header: this.$t('login_history.table.header.name'),
          label: this.record.name,
        },
        {
          header: this.$t('login_history.table.header.logged_in'),
          label: this.record.login,
          format: Formats.DATE,
        },
      ];
    },
  },
};
</script>
