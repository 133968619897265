<template>
  <li class="ListItem">
    <slot />
  </li>
</template>

<script>
export default {
  name: 'ListItem',
};
</script>

<style lang="scss" scoped>
.ListItem {
  display: flex;

  @include maxBreakpoint(760) {
    margin-right: 20px;
    justify-content: space-between;
  }

  @include maxBreakpoint(500) {
    flex-direction: column;
    gap: 10px;
  }
}
</style>
