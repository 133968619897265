<template>
  <div class="NewsletterManager">
    <FadeTransition :duration="0.6">
      <NewsletterCheckbox v-if="!checked" id="newsletterCheckbox" v-model="checked" />
      <div v-else>
        <span>Modtag nyhedsbrev på e-mail:</span>
        <List>
          <ListItem>
            <ListItemContent :title="'aaa@aaa.aa'">
              {{ accountContainer.account.email_address }}
            </ListItemContent>
            <ListItemControls>
              <Link
                :disabled="false"
                @click="$refs.editNewsletterEmailModal.open(accountContainer.account.email_address)"
              >
                {{ $t('profile.controls.edit') }}
              </Link>
              <Link :disabled="false" @click="$refs.unsubscribeNewsletterModal.open()">
                Afmeld
              </Link>
            </ListItemControls>
          </ListItem>
        </List>
      </div>
    </FadeTransition>
    <EditNewsletterEmailModal ref="editNewsletterEmailModal" />
    <UnsubscribeNewsletterModal ref="unsubscribeNewsletterModal" @submit="checked = false" />
  </div>
</template>

<script>
import Link from '@components/Link';
import FadeTransition from '@transitions/Fade';
import List, { ListItem, ListItemContent, ListItemControls } from '../List';
import { NewsletterCheckbox } from '@components/Checkbox';
import EditNewsletterEmailModal from './components/EditNewsletterEmailModal';
import UnsubscribeNewsletterModal from './components/UnsubscribeNewsletterModal';

export default {
  name: 'NewsletterManager',

  components: {
    Link,
    FadeTransition,
    List,
    ListItem,
    ListItemContent,
    ListItemControls,
    NewsletterCheckbox,
    EditNewsletterEmailModal,
    UnsubscribeNewsletterModal,
  },

  data() {
    return {
      checked: false,
    };
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>
