<template>
  <div class="TagCollection">
    <div class="TagCollection__items">
      <Tag
        v-for="item in items"
        :label="item"
        :key="item"
        v-model="checkedItems"
        :disabled="disabled || (maxReached && checkedItems.indexOf(item) === -1)"
      />
    </div>
    <div class="TagCollection__alert" v-if="showCount && min > 0 && min > checkedItems.length">
      Du mangler at vælge {{ min - checkedItems.length }}
    </div>
    <div class="TagCollection__alert" v-else-if="showCount && max > 0 && checkedItems.length > 0">
      Du har valgt {{ checkedItems.length }} ud af {{ max }}
    </div>
  </div>
</template>

<script>
import Tag from './components/Tag';

export default {
  name: 'TagCollection',

  components: {
    Tag,
  },

  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    max: {
      type: Number,
      default: -1,
    },
    min: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    showCount() {
      return this.max > 0 || this.min > 0;
    },
    maxReached() {
      return this.max !== -1 && this.checkedItems.length >= this.max;
    },
    checkedItems: {
      get() {
        return this.value;
      },
      set(checkedItems) {
        if (this.max !== -1 && checkedItems.length > this.max) {
          this.$emit('maxReached');
          return;
        }
        this.$emit('input', checkedItems);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.TagCollection {
  .TagCollection__items {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .TagCollection__alert {
    margin-top: 10px;
    font-style: italic;
    // border: 1px solid #3071a9;
    // background: #d5eaff;
    // color: #333;
    // padding-top: 10px;
    // padding-bottom: 10px;
    // padding-left: 5px;
  }
}
</style>
