<template>
  <time :datetime="rawDatetime" class="Time">{{ formattedDatetime }}</time>
</template>

<script>
import navigatorLanguages from 'navigator-languages';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import './locales/nb';
import './locales/da';

import helpers from '@helpers';

dayjs.extend(localizedFormat);

export default {
  name: 'Time',

  props: {
    datetime: {
      type: String,
      required: true,
    },

    format: {
      required: false,
      type: String,
      default: '',
    },
    showDate: {
      required: false,
      type: Boolean,
      default: true,
    },
    showTime: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  computed: {
    formattedDatetime() {
      return helpers.manipulation.formattedDatetime({
        datetime: this.datetime,
        format: this.format,
        showDate: this.showDate,
        showTime: this.showTime,
      });
    },

    rawDatetime() {
      if (this.datetime !== undefined && this.datetime !== null && this.datetime !== '') {
        return dayjs(this.datetime).format();
      }

      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.Time {
  // text-transform: capitalize; Bug making "M" in 3. Maj uppercase.
}
</style>
