<template>
  <div class="Pagination">
    <ul class="Pagination__controls" role="navigation" aria-label="Pagination Navigation">
      <li class="Pagination__items">
        <a
          :class="['Pagination__item', page === 1 && 'Pagination__item--disabled']"
          @click.prevent="previousPage"
          @keyup.enter="previousPage"
          :tabindex="page === 1 ? -1 : 0"
        >
          <i class="fas fa-chevron-left Pagination__icon" />
        </a>
      </li>

      <li v-for="displayedPage in displayedPages" :key="displayedPage" class="Pagination__items">
        <a
          v-if="displayedPage"
          :aria-label="'side ' + displayedPage"
          @click.prevent="changePage(displayedPage)"
          @keyup.enter="changePage(displayedPage)"
          :class="['Pagination__item', displayedPage === page && 'Pagination__item--active']"
          tabindex="0"
        >
          {{ displayedPage }}
        </a>
        <span class="Pagination__ellipsis" v-else>...</span>
      </li>

      <li class="Pagination__items">
        <a
          :class="[
            'Pagination__item',
            (page === total || total === 0) && 'Pagination__item--disabled',
          ]"
          @click.prevent="nextPage"
          @keyup.enter="nextPage"
          :tabindex="page === total ? -1 : 0"
        >
          <i class="fas fa-chevron-right Pagination__icon" />
        </a>
      </li>
    </ul>
    <p class="Pagination__printInfo">{{ $t('page_n_of_total', { n: page, total: total }) }}</p>
  </div>
</template>

<script>
import { gsap } from 'gsap/all';

export default {
  name: 'Pagination',

  props: {
    page: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      default: 7,
      validator(value) {
        if (value >= 6) return true;
        console.error('Pagination: Limit cant be below 6 ' + value);
        return false;
      },
    },
    routing: {
      type: Boolean,
      default: false,
    },
    scrollTo: {
      type: Element,
      default: undefined,
    },
  },

  computed: {
    displayedPages() {
      const pages = [];
      if (this.total <= this.limit) {
        for (let i = 1; i <= this.total; i++) {
          pages.push(i);
        }
        return pages;
      }
      if (this.page <= Math.ceil(this.limit / 2)) {
        for (let i = 1; i <= this.limit - 2; i++) {
          pages.push(i);
        }
        pages.push(null, this.total);
        return pages;
      }
      if (this.page > this.total - Math.floor(this.limit / 2) - 1) {
        pages.push(1, null);
        for (let i = this.total - this.limit + 3; i <= this.total; i++) {
          pages.push(i);
        }
        return pages;
      }
      const from = this.page - Math.ceil((this.limit - 4) / 2) + 1;
      const to = this.page + Math.floor((this.limit - 4) / 2);
      pages.push(1, null);
      for (let i = from; i <= to; i++) {
        pages.push(i);
      }
      pages.push(null, this.total);
      return pages;
    },
  },

  watch: {
    total(to) {
      if (to < this.page) {
        to === 0 ? this.changePage(1) : this.changePage(to);
      }
    },
  },

  methods: {
    changePage(page) {
      this.$emit('change', page);
      if (this.scrollTo) this.scrollUp();
    },
    previousPage() {
      if (this.page > 1) this.changePage(this.page - 1);
    },
    nextPage() {
      if (this.page < this.total) this.changePage(this.page + 1);
    },
    scrollUp() {
      gsap.to(window, { duration: 0.5, delay: 0, scrollTo: { y: this.scrollTo, offsetY: 80 } });
    },
  },
};
</script>

<style lang="scss" scoped>
.Pagination {
  .Pagination__printInfo {
    text-align: center;
    display: none;
    @media print {
      display: block;
    }
  }
  .Pagination__controls {
    display: flex;
    gap: 15px;
    user-select: none;
    justify-content: center;

    @media print {
      display: none;
    }
  }
  a {
    text-decoration: none;
    align-items: center;
  }

  .Pagination__items {
    width: 24px;
  }

  .Pagination__item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 5px 15px;
    text-align: center;
    border: 1px solid #ffffff;
    border-radius: 3px;

    &:not(.Pagination__item--active):not(.Pagination__item--disabled) {
      &:hover {
        background-color: $color-prim-grey;
        cursor: pointer;
        transition: 0.3s;
      }

      &:focus {
        outline: none;
        background-color: $color-prim-grey;
      }
    }

    &--active {
      border-color: $color-prim-blue;
      transition: 0.3s;
      pointer-events: none;
      &:focus {
        outline: none;
      }
    }

    &--disabled {
      color: $color-prim-grey;
      &:focus {
        outline: none;
      }
    }
  }
  .Pagination__icon {
    font-size: 14px;
  }

  .Pagination__ellipsis {
    padding: 0px 10px;
  }
}
</style>
