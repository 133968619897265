<template>
  <span class="ListItemContent" :title="title"> <slot /></span>
</template>

<script>
export default {
  name: 'ListItemContent',

  components: {},

  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.ListItemContent {
  color: #888888;
  font-weight: bold;
  width: 210px;

  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
