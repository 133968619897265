<template>
  <Validator v-slot="{ valid, showErrors }">
    <ModalDialog
      ref="modal"
      :title="$t('profile.controls.edit_email--long')"
      :working="working"
      :confirmLabel="$t('profile.controls.save')"
      :confirmColor="ButtonColors.RED"
      @confirm="valid ? submit() : showErrors()"
    >
      <template v-if="error">
        <Alert v-if="error === 'exists'" :level="AlertLevel.WARNING" hideIcon closeable>
          {{ $t('profile.message.email_already_added') }}
        </Alert>
        <Alert v-else :level="AlertLevel.ERROR" hideIcon closeable>
          {{ $t('error_messages.try_again') }}
        </Alert>
        <br />
      </template>
      <Input
        id="newsletterEmail"
        v-model="editToEmail"
        name="newsletterEmail"
        type="email"
        :label="$t('profile.controls.new_email')"
        validate-on-blur
        required
      />
    </ModalDialog>
  </Validator>
</template>

<script>
import Validator from '@containers/Validator';
import { ModalDialog } from '@components/Modal';
import Alert, { AlertLevel } from '@components/Alert';
import { colors as ButtonColors, icons as ButtonIcons } from '@components/Buttons';
import Input from '@scenes/SelfserviceScene/components/Input';

export default {
  name: 'EditNewsletterEmailModal',

  components: {
    Validator,
    ModalDialog,
    Alert,
    Input,
  },

  enums: {
    AlertLevel,
    ButtonColors,
    ButtonIcons,
  },

  data() {
    return {
      working: false,
      email: '',
      editToEmail: '',
      error: '',
    };
  },

  methods: {
    submit() {
      if (this.accountContainer.hasEmail(this.editToEmail)) {
        this.error = 'exists';
        return;
      }
      this.working = true;
      this.accountContainer
        .editEmail({
          from: this.email,
          to: this.editToEmail,
        })
        .then(e => {
          this.close();
        })
        .catch(errors => {
          this.working = false;
          const errorCode = errors && errors.length ? errors[0]?.code : '';
          this.error = errorCode === 'mail_occupied' ? 'occupied' : 'error';
        });
    },
    open(email) {
      this.email = this.editToEmail = email;
      this.error = '';
      this.$refs.modal.open();
    },
    close() {
      this.error = '';
      this.$refs.modal.close();
    },
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>
