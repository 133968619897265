<template>
  <Modal
    ref="modal"
    :title="$t('profile.controls.change_password')"
    :working="status === Progress.WORKING"
  >
    <Validator v-slot="{ valid, showError }" ref="validator">
      <Form
        :locked="status === Progress.WORKING"
        class="ChangePasswordForm"
        @submit="valid ? changePassword() : showError()"
      >
        <template #default>
          <Input
            id="current-password"
            name="current-password"
            type="password"
            :label="$t('account.auth.current_password')"
            required
            :autocomplete="Autocompletes.PASSWORD_CURRENT"
            autoFocus
            v-model="currentPassword"
          />
          <NewPasswordInputs
            ref="newPasswordInputs"
            @input="password => (newPassword = password)"
          />
        </template>
        <template #footer>
          <div class="ChangePasswordForm__footer">
            <Collapse>
              <div v-if="status === Progress.COMPLETE" class="ChangePasswordForm__alertWrapper">
                <Alert :level="AlertLevel.SUCCESS" hideIcon class="ChangePasswordForm__alert">
                  {{ completeMessage }}
                </Alert>
              </div>
              <div v-else-if="status === Progress.ERROR" class="ChangePasswordForm__alertWrapper">
                <Alert :level="AlertLevel.ERROR" hideIcon class="ChangePasswordForm__alert">
                  {{ errorMessage }}
                </Alert>
              </div>
            </Collapse>
            <div class="ChangePasswordForm__controls">
              <Button @click.prevent="close" :color="ButtonColor.WHITE">{{ closeLabel }}</Button>
              <Button>{{ $t('account.auth.save_password') }}</Button>
            </div>
          </div>
        </template>
      </Form>
    </Validator>
  </Modal>
</template>

<script>
import Progress from '@types/Progress';
import Validator from '@containers/Validator';
import Form from '@layouts/Form';
import Collapse from '@transitions/Collapse';
import { Modal } from '@components/Modal';
import NewPasswordInputs from '@components/NewPasswordInputs';
import Alert, { AlertLevel } from '@components/Alert';
import { Button, colors as ButtonColor } from '@components/Buttons';
import Input, { Autocompletes } from '@scenes/SelfserviceScene/components/Input';

const ErrorStatus = {
  WRONG_PASSWORD: 'WRONG_PASSWORD',
  OTHER: 'OTHER',
};

export default {
  name: 'ChangePasswordForm',

  components: {
    Validator,
    Form,
    Collapse,
    Modal,
    NewPasswordInputs,
    Alert,
    Button,
    Input,
  },

  enums: {
    Progress,
    AlertLevel,
    ButtonColor,
    Autocompletes,
  },

  data() {
    return {
      currentPassword: '',
      newPassword: '',
      status: '',
      errorStatus: '',
    };
  },

  computed: {
    completeMessage() {
      return this.$t('account.auth.message.password_saved');
    },
    errorMessage() {
      if (this.errorStatus === ErrorStatus.WRONG_PASSWORD)
        return this.$t('account.auth.message.password_error');
      return this.$t('account.auth.message.error');
    },
    closeLabel() {
      if (this.status === Progress.COMPLETE) return this.$t('profile.controls.close_modal');
      return this.$t('cancel');
    },
  },

  methods: {
    open() {
      this.currentPassword = '';
      this.status = '';
      this.errorStatus = '';
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    changePassword() {
      this.status = Progress.WORKING;
      this.authenticationContainer
        .fetchToken()
        .then(token => {
          const extra = { old_password: this.currentPassword };
          this.authenticationContainer
            .changePassword(token, this.newPassword, extra)
            .then(this.onChangeCompleted)
            .catch(this.onChangeFailed);
        })
        .catch(this.onChangeFailed);
    },
    onChangeCompleted() {
      this.status = Progress.COMPLETE;
      this.currentPassword = '';
      this.$refs.newPasswordInputs.clear();
    },
    onChangeFailed(errors) {
      const wrongPassword =
        Array.isArray(errors) && errors.some(error => error.code === 'PasswordMismatch');
      this.errorStatus = wrongPassword ? ErrorStatus.WRONG_PASSWORD : ErrorStatus.OTHER;
      this.status = Progress.ERROR;
    },
  },

  inject: {
    authenticationContainer: {
      default() {
        console.error('Missing AuthenticationContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.ChangePasswordForm {
  .ChangePasswordForm__footer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .ChangePasswordForm__alertWrapper {
    width: 100%;
  }

  .ChangePasswordForm__alert {
    width: 100%;
    margin-bottom: 20px;
  }

  .ChangePasswordForm__controls {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
</style>
