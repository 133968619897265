<template>
  <div class="InputList">
    <slot name="default" />
  </div>
</template>

<script>
export default {
  name: 'InputList',
};
</script>

<style lang="scss" scoped>
.InputList {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;

  > * {
    width: 100%;
  }
}
</style>
